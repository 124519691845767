import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useRequest from '@shared/services/hooks/useRequest';
import { currencyFormat } from '@shared/services/numberHelpers';

export default function PaymentsList({
  invoice, baseUrl, hideDescription = false, includeSmsSend = false,
}) {
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [openSmsDialog, setOpenSmsDialog] = React.useState(false);
  const [paymentId, setPaymentId] = React.useState(null);
  const [email, setEmail] = React.useState(invoice.patientEmail);
  const { request } = useRequest();

  const handleOpenEmailDialog = (id) => {
    setPaymentId(id);
    setOpenEmailDialog(true);
  };

  const handleCloseEmailDialog = () => {
    setPaymentId(null);
    setOpenEmailDialog(false);
  };

  const handleOpenSmsDialog = (id) => {
    setPaymentId(id);
    setOpenSmsDialog(true);
  };

  const handleCloseSmsDialog = () => {
    setPaymentId(null);
    setOpenSmsDialog(false);
  };

  const sendReceiptEmail = async () => {
    await request({
      url: `${baseUrl}/${paymentId}/send_receipt_email`,
      method: 'POST',
      data: {
        email,
      },
      snackbar: true,
      disableLoading: true,
      snackbarSuccessText: 'Receipt sent successfully',
    });
    handleCloseEmailDialog();
  };

  const sendReceiptSms = async () => {
    await request({
      url: `${baseUrl}/${paymentId}/send_receipt_sms`,
      method: 'POST',
      snackbar: true,
      disableLoading: true,
      snackbarSuccessText: 'Receipt sent successfully',
    });
    handleCloseSmsDialog();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        { !hideDescription && (
          <Typography>
            Your Payment
            {invoice.payments.length > 1 ? 's' : ''}
          </Typography>
        )}
        {invoice.payments.map((payment) => (
          <Box
            key={payment.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>
              {currencyFormat(payment.amount, { maximumFractionDigits: 2 })}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="outlined" color="primary" href={`${baseUrl}/${payment.id}/receipt.pdf`} target="_blank">
                View Receipt
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenEmailDialog(payment.id)}
              >
                Email
              </Button>
              {includeSmsSend && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenSmsDialog(payment.id)}
                >
                  SMS
                </Button>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Dialog
        open={openEmailDialog}
        onClose={handleCloseEmailDialog}
        aria-labelledby="email-dialog-title"
        aria-describedby="email-dialog-description"
      >
        <DialogTitle id="email-dialog-title">Email Receipt</DialogTitle>
        <DialogContent>
          <DialogContentText id="email-dialog-description">
            To whom would you like to send this receipt?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={sendReceiptEmail} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
      { includeSmsSend && (
        <Dialog
          open={openSmsDialog}
          onClose={handleCloseSmsDialog}
          aria-labelledby="sms-dialog-title"
          aria-describedby="sms-dialog-description"
        >
          <DialogTitle id="sms-dialog-title">Send SMS</DialogTitle>
          <DialogContent>
            <DialogContentText id="sms-dialog-description">
              Sending to
              {' '}
              {invoice.prettyPhone}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSmsDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={sendReceiptSms} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
