import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { currencyFormat } from '@shared/services/numberHelpers';
import { PaymentContext } from '../../context/PaymentContext';

export default function Invoice() {
  const { invoice, setStep } = useContext(PaymentContext);

  return (
    <>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
      }}
      >
        <Typography variant="h6" sx={{ my: 4 }}>
          Invoice
        </Typography>
        <Button color="primary" onClick={() => setStep(2)}>
          Back to payment
        </Button>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 8, mb: 4,
      }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between',
          }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography>
                Payment Status
              </Typography>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {invoice.status}
              </Typography>
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography>
                Due Date
              </Typography>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {invoice.dueDate}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography>
              Bill To
            </Typography>
            <Typography variant="h6">
              {invoice.streetAddress}
              <br />
              {invoice.cityStateZip}
              <br />
              {invoice.primaryPhone}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="overline">
            Service Details
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography>
                Date
              </Typography>
              <Typography variant="h6">
                {invoice.serviceDate}
              </Typography>
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography>
                Patient
              </Typography>
              <Typography variant="h6">
                {invoice.patientName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography>
              Description
            </Typography>
            <Typography variant="h6">
              {invoice.description}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="overline">
            Balance
          </Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Amount Paid
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amountPaid, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Amount Due
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amountDue, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Total Patient Balance
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amountDue, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
